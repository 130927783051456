/*
 * @license
 * Copyright 2017 Google LLC All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const config = {
  apiKey: "AIzaSyD8fXxC_iqfIX8803xDdnKMVeguppH9CRA",
  authDomain: "liubin-dev2.firebaseapp-staging.com",
  databaseURL: "https://liubin-dev2-default-rtdb.firebaseio.com",
  projectId: "liubin-dev2",
  storageBucket: "liubin-dev2.appspot.com",
  messagingSenderId: "738581737745",
  appId: "1:738581737745:web:287e445ffd0617e0dcdcb0"
};
